import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import '../styles/contactForm.css'; // Import the CSS file

function ContactForm() {
    const [state, handleSubmit] = useForm("xwkgqwlw");

    if (state.succeeded) {
        return <>
            <p style={{ fontSize: '30px', textAlign: 'center', paddingTop: '40px' }}>Thanks for reaching out! We'll get back to you soon!</p>

        </>;
    }

    return (
        <div className="formC"> {/* Apply the class to the form container */}
            <h2 className="cForm">Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        className="formInput"
                        placeholder="Name"
                        required
                    />
                    <ValidationError
                        prefix="Name"
                        field="name"
                        errors={state.errors}
                        className="errorText"
                    />
                </div>
                <div>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        className="formInput"
                        placeholder="Email"
                        required
                    />
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                        className="errorText"
                    />
                </div>
                <div>
                    <textarea
                        id="message"
                        name="message"
                        className="formInput"
                        placeholder="Your message..."
                        required
                    />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                        className="errorText"
                    />
                </div>
                <button type="submit" disabled={state.submitting} className="submit">Submit</button> {/* Apply the class to the submit button */}
            </form>
        </div>
    );
}

export default ContactForm;
