import React from "react";
import Carousel from '../components/Carousel';
import '../styles/home.css'
import Button from '@mui/material/Button';
import { Grid, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import bImage from '../assets/Cover.png';
import ContactForm from "../components/ContactForm";
import mushR from '../assets/mushR.png';
import Vend from '../assets/Vending.png';
import ID from '../assets/ID.png';





const theme2 = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#fff',
            darker: '#80808080',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#000000',
        },
    },
    typography: {
        fontSize: 16, // Change this value to adjust the font size
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    width: '80%',
                    maxWidth: '300px',

                    marginTop: '20px',
                    marginBottom: '38px',
                    '&:hover': {
                        color: '#97c4d0', // Change this value to your desired hover color
                        borderColor: '#97c4d0', // Optional: Changes the border color on hover
                    },
                },
            },
        },
    },
});

const theme3 = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#fff',
            darker: '#80808080',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#000000',
        },
    },
    typography: {
        fontSize: 16, // Change this value to adjust the font size
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    border: '1px solid #ffffff', // Adds a white border to the button
                    width: '80vw',
                    marginTop: '-25px',
                    marginBottom: '30px',
                    '&:hover': {
                        color: '#97c4d0', // Change this value to your desired hover color
                        borderColor: '#97c4d0', // Optional: Changes the border color on hover
                    },
                },
            },
        },
    },
});


function Home() {

    return (
        <>
            <Carousel />
            <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                <img src={ID} style={{ width: '80vw', maxWidth: '800px', height: 'auto' }} />
            </div>
            <div style={{
                maxWidth: '800px',
                margin: '0 auto',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingBottom: '20px'
            }}>
                <p style={{ textAlign: 'center', paddingRight: '38px', paddingLeft: '38px', fontSize: 20 }}>
                    Hey there, Morty here! You know, life can be a real rollercoaster, full of ups and downs, twists, and turns. But guess what, folks? We've stumbled upon something that's gonna take you on a journey to a whole new dimension – introducing Morty’s Inter-Dimensional Gummies, the mind-bending psychedelic gummy that's gonna flip your reality upside down, in the best way possible!
                </p>
            </div>

            <div style={{ textAlign: 'center', paddingBottom: '40px' }}>
                <img src={Vend} style={{ height: '500px', width: 'auto' }} />
            </div>

            <div style={{ textAlign: 'center' }}>
                <ThemeProvider theme={theme3}>
                    <Button color='primary' onClick={() => window.location.href = '/product'}>View Our Product</Button>
                </ThemeProvider>
            </div>

            <Box sx={{
                position: 'relative',
                width: 'auto',
                height: '450px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '-5px',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${bImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.3, // Adjust background image opacity here
                    zIndex: -1, // Ensure the overlay is behind the content

                }, '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0)', // Adjust the opacity (last parameter) here
                    zIndex: -2, // Ensure the overlay is behind the background image
                },
            }}>
                <Typography variant="h4" component="h1" sx={{ fontSize: '26px', color: '#fff', textAlign: 'center', mt: 2, margin: '30px', marginBottom: '10px' }}>
                    Intergalactic Travel Without The Bureaucracy
                </Typography>
                <Typography variant="p1" component="p1" sx={{ color: '#fff', textAlign: 'center', mt: 2, margin: '10px', maxWidth: '82vw' }}>
                    Buckle up friends, because now intergalactic travel is possible for everyone! That’s right, I said EVERYONE!! No need for a portal gun, a spaceship, or any of that intergalactic bureaucracy anymore because these gummies will send you on a journey unlike anything you’ve ever been on.
                </Typography>
                {/* <ThemeProvider theme={theme2}>
                    <Button color='primary' onClick={() => window.location.href = '/faq'}>FAQ</Button>
                </ThemeProvider> */}
            </Box>

            <ContactForm />
        </>
    );
}

const styles = {
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'center',
    },
    contentImage: {
        width: '95%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center 30%',
        borderRadius: '8px',
        margin: '20px auto',
    },
    textContent: {
        width: '95%',
        margin: '20px auto',
        border: '3px solid rgba(255,255,255, 0.6)',
        padding: '20px',
        borderRadius: '8px',
        color: 'white',
        backgroundColor: 'transparent',
    },
    textContentC: {
        width: '100%',
        maxWidth: '400px',
        margin: '20px auto',
        borderRadius: '8px',
        color: 'white',
        backgroundColor: 'transparent',
        textAlign: 'center',
        fontSize: '21px'
    },
    sectionHeading: {
        color: 'white',
        textAlign: 'left',
        fontSize: '26px',
    },
};


export default Home;
