import ContactForm from "../components/ContactForm";


function NavbarComponent() {

  return (
    <ContactForm />
  );
};

export default NavbarComponent;
