import React from 'react';
import '../styles/product.css'; // Importing the CSS for styling
import Product from '../assets/Gummies.png'
import CL1 from '../assets/CL1.png';
import CL2 from '../assets/CL2.png';
import CL3 from '../assets/CL3.png';
import { Grid } from '@mui/material';
import IDG from '../assets/IDG.png';
import Ship from '../assets/Ship.png';


import ContactForm from "../components/ContactForm";

import ImageGrid from '../components/ImgageGrid';

const ProductDisplayPage = () => {
  return (
    <>
      <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
        <img src={IDG} style={{ width: '90vw', maxWidth: '800px', height: 'auto', }} />
      </div>

      <Grid container spacing={0} justifyContent="center" style={{ marginTop: '-15px' }}>
        <Grid item xs={12} md={6} style={styles.gridItem}>
          <img src={Product} alt="Related Content" style={styles.contentImage} />

        </Grid>
        <Grid item xs={12} md={6} style={styles.gridItem}>
          <div style={styles.textContent}>
            <h2 style={styles.sectionHeading}>Mushroom Gummies</h2>
            <p style={{ fontSize: '17px' }}>These gummies aren't your average snacks, oh no. They're a wild ride through flavor town, with a whole lot of adventure that'll make your taste buds scream, 'Wubba lubba dub dub!' Each gummy is individually infused with just the right amount of lab tested psilocybin and packed with a blend of exotic flavors, Morty’s Inter-Dimensional Gummies isn’t just a gummy; it's a flavor-packed portal to another dimension.
              <br />
              <br />
              Now, I know what you're thinking – 'Morty, are these gummies safe?' Well, fear not! Each of these gummies is crafted with care and precision using only lab tested raw bodies ensuring a cosmic experience without the cosmic chaos. So, buckle up, get ready to taste the rainbow of realities, and let my Morty’s Interdimensional Gummies take you on a flavor journey you won't soon forget.
              <br />
              <br />
              Chew responsibly
            </p>
          </div>
        </Grid>
      </Grid>
      <div style={{ textAlign: 'center', paddingBottom: '40px' }}>
        <img src={Ship} style={{ width: '90vw', maxWidth: '800px', height: 'auto', }} />
      </div>

      <div className="news-container">
        <div className="news-item">
          <h2>Lab Tested, Morty Approved</h2>
          <p>Nobody should have to worry about cosmic consequences from a trip through the galaxy or ending up in a dimension that you didn’t sign up for. Which is why we only use <u><b>lab tested raw fruiting bodies</b></u> as the backbone of our Interdimensional Gummies; we <u><b>never use extract or synthetic.</b></u> Our gummies are guaranteed to be free of heavy metals, contaminants, grain, starch, and filler. Because safety first, you know?</p>
        </div>
        <div className="news-item">
          <h2>Premium Ingredients and Quality Taste</h2>
          <p>Your adventure starts at flavor town because each gummy is meticulously crafted with care and precision. Our ingredients were carefully collected and formulated to create a kaleidoscope of taste and fuel your psychedelic journey.</p>
        </div>
        <div className="news-item">
          <h2>Precision Dosing</h2>
          <p>Random fluctuations in dosages are bad!!! Especially when you’re exploring different timelines with interdimensional travel!!! That’s why we individually infuse each Interdimensional Gummy with 0.75g of lab tested psilocybin to ensure each piece delivers the same dependable mind bending experience.</p>
        </div>
        <div className="news-item">
          <h2>Legal Compliance</h2>
          <p>Check your local laws regarding the possession and use of psilocybin containing products.</p>
        </div>
        <div className="news-item">
          <h2>Ordering Information</h2>
          <p>For retail & wholesale ordering information please <a href="./contact" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Contact Us.</a></p>
        </div>
      </div>
      <div style={{ marginBottom: '-35px' }} />
      <ContactForm />


    </>

  );
};

const styles = {
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'center',
  },
  contentImage: {
    width: '95%',
    minHeight: '300px',
    objectFit: 'cover',
    objectPosition: 'center 30%',
    borderRadius: '8px',
    margin: '20px auto',
  },
  textContent: {
    width: '95%',
    margin: '20px auto',
    padding: '10px',
    borderRadius: '8px',
    color: 'white',
    backgroundColor: 'transparent',
  },
  textContentC: {
    width: '100%',
    maxWidth: '400px',
    margin: '20px auto',
    borderRadius: '8px',
    color: 'white',
    backgroundColor: 'transparent',
    textAlign: 'center',
    fontSize: '21px'
  },
  sectionHeading: {
    color: 'white',
    textAlign: 'left',
    fontSize: '30px',
  },
};

export default ProductDisplayPage;
